import VehicleCard from '@/components/atomic/VehicleCard'
import { Carousel } from '@/components/styleguide/Homepage/Carousel'
import { SearchResult } from '@/types/search'

export const DEALS_UNDER_300 = 'under-350'
export const ALL_DEALS = 'all'

export type TopDealsProps = {
  isVans: boolean
  type?: typeof DEALS_UNDER_300 | typeof ALL_DEALS
  dealList: SearchResult[]
  isVariant?: boolean
}

export const TopDeals = ({
  isVans,
  dealList,
  type = ALL_DEALS,
  isVariant = false,
}: TopDealsProps) => {
  return (
    <Carousel
      header={
        <>
          <p>Picked by Luis</p>
          <h2>
            {type === DEALS_UNDER_300
              ? `${isVans ? 'Van' : 'Car'} leasing for under £300/pm`
              : `Today's top ${isVans ? 'van' : 'car'} lease deals`}
          </h2>
        </>
      }
      className={isVariant ? 'variant' : ''}
      scrollValue={408}
      moreLink={
        isVans
          ? 'van-leasing/search'
          : type === DEALS_UNDER_300
            ? 'car-leasing/budget/300'
            : 'car-leasing/search'
      }
      moreLabel={`${type === DEALS_UNDER_300 ? 'cheap' : 'of the best'} deals`}
      sectionName={`${type === DEALS_UNDER_300 ? 'cheapest' : 'best'}-deals`}>
      {dealList.map(deal => (
        <VehicleCard
          deal={deal}
          key={deal.vehiclePrice.id}
        />
      ))}
    </Carousel>
  )
}
